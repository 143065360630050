<template>
  <div id="financial-consulting">
    <v-container class="fill-height">
      <v-row justify="center" align="center">
        <!-- 좌 -->
        <v-col cols="12" md="6" offset-md="1">
          <div class="left" :class="{ mobile: !$vuetify.breakpoint.smAndUp }">
            <h2>법인 재무 컨설팅</h2>
            <h2>아직도 안 받아보셨나요?</h2>
          </div>
        </v-col>

        <!-- 우 -->
        <v-col cols="12" md="5">
          <div class="right" :class="{ mobile: !$vuetify.breakpoint.smAndUp }">
            <v-timeline dense align-top light>
              <!-- 1 -->
              <v-timeline-item small class="mb-4">
                <h3>개인 사업자 법인 전환</h3>
              </v-timeline-item>

              <!-- 2 -->
              <v-timeline-item small class="mb-4">
                <h3 class="mb-2">법인 Total Care Service</h3>
                <h4>• 지분 구조</h4>
                <h4>• 소득 구조</h4>
                <h4>• 이익환원 구조</h4>
              </v-timeline-item>

              <!-- 3 -->
              <v-timeline-item small>
                <h3 class="mb-2">법인 컨설팅</h3>
                <h4>• 미처분이익 잉여금</h4>
                <h4>• 주식가치 조정</h4>
                <h4>• 가지급금 해결</h4>
                <h4>• 명의신탁 주식</h4>
              </v-timeline-item>
            </v-timeline>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data: () => ({})
}
</script>

<style lang="scss" scoped>
#financial-consulting {
  height: 100vh;

  // 좌
  .left {
    text-align: left;

    h2 {
      font-size: 48px;
      font-weight: 800 !important;
      color: $color-black2;
    }

    &.mobile {
      text-align: center;

      h2 {
        font-size: 24px;
      }
    }
  }

  // 우
  .right {
    h3 {
      font-size: 24px;
    }

    h4 {
      font-size: 20px;
      margin-bottom: 4px;
    }

    &.mobile {
      h3 {
        font-size: 20px;
      }

      h4 {
        font-size: 16px;
      }
    }
  }
}
</style>
